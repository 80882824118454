<template>
  <help-page class="help-page" title="common.app-online-help">
    <el-card shadow="hover">
      <div class="title">{{ $t('help.online-help-cate') }}</div>
      <hr class="split-line" />
      <div class="content">
        <div
          class="question-item"
          v-for="(item, index) in questionList"
          :key="index"
          @click="goToSubMenu(item.value, item.key)"
        >
          {{ index + 1 }}.{{ item.key }}
        </div>
      </div>
    </el-card>
  </help-page>
</template>

<script setup>
import helpPage from '../help-page.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { ref } from 'vue'
const store = useStore()
const router = useRouter()

const questionList = ref([])
const initData = async () => {
  const res = await store.dispatch('help/getHelpLists')
  questionList.value = res
}
const goToSubMenu = (type, title) => {
  router.push(`/article/question/${type}/${title}`)
}
initData()
</script>

<style scoped lang="scss">
.help-page {
  .title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 18px;
  }

  .split-line {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #e9e9eb;
    background-color: #e9e9eb;
    height: 1px;
    border: none;
  }
  .content {
    .question-item {
      font-size: 14px;
      cursor: pointer;
      color: #285a9d;
      margin-bottom: 10px;
    }
  }
}
</style>
